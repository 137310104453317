import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import PatentStrategyPopup from '../components/Popup/patent-strategy-popup';
import SEO from "../components/seo";
import '../styles/provisional-builder.css';

const PatentStrategy = () => {
    const seo = {
        metaDesc: 'Strategic Patent Protection - Inventiv.org'
    }

    const listStyles = {
        listStyle: 'disc',
        paddingLeft: '20px'
    }

    //
    const childRef = useRef();
    const openPopup = () => {
        childRef.current?.open();
    }
    
    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <>
            <Layout activeMenu={'builder'}>
                <SEO title="Strategic Patent Protection - Inventiv.org" canonical='/patent-strategy' seo={seo} />

                {/* <!-- Banner start --> */}
                <div class="banner banner-2 bg-light-blue">
                    <div class="container">
                        <div class="row ">
                            <div class="col-12 col-md-6 mt-3">
                                <h1 class="sec-clr bold">
                                    Boost Your Company's Value with Strategic Patent Protection
                                </h1>
                                <p class="sec-clr">
                                    Innovative inventors like you deserve maximum returns on your ideas. A well-crafted patent strategy is your secret weapon for increasing company valuation and safeguarding your go-to-market plans.
                                </p>
                                <p className='mt-3'>
                                    <button type='button' onClick={openPopup} class="btn-default btn">Get Your Free Patent Strategy Call</button>
                                </p>
                            </div>
                            <div class="col-12 col-md-6 mt-3">
                                <img src='/assets/img/strategy/strategy-1.jpeg' style={{ width: '100%'}}/>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!-- banner end --> */}

                <section class="pt-3 pb-3 mt-3 mb-3" id="learn-more">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                                <h1 class="bold sec-clr mt-6">Unlock <span class="d-clr">Hidden Value in Your Innovations</span></h1>
                                <p class="sec-clr f-16">
                                    Don't let your groundbreaking inventions go unprotected. Our expert patent attorneys will help you:
                                </p>
                                <p>
                                    <ul style={listStyles} class="sec-clr f-16">
                                        <li>Identify and protect your most valuable intellectual property</li>
                                        <li>Build a robust patent portfolio that attracts investors</li>
                                        <li>Increase your company's valuation through strategic patenting</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pt-3 pb-3 mt-3 mb-3 provisionalBuilder-sec-2 bg-light-blue" id="read-more">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                                <img src='/assets/img/strategy/strategy-2.jpeg' style={{ width: '100%'}}/>
                            </div>
                            <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                                <h1 class="sec-clr bold">
                                    Defend <span class="d-clr">Your Market Position</span>
                                </h1>
                                <p class="sec-clr f-16">
                                    A strong patent strategy is crucial for protecting your competitive advantage:
                                </p>
                                <p>
                                    <ul style={listStyles} class="sec-clr f-16">
                                        <li>Create barriers to entry for competitors</li>
                                        <li>Secure freedom to operate in your target markets</li>
                                        <li>Safeguard your go-to-market strategy from copycats</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="pt-3 pb-3 mt-3 mb-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
                                <h1 class="bold sec-clr">Maximize <span class="d-clr">ROI on Your R&D</span></h1>
                                <p class="sec-clr f-16">
                                    Turn your innovative ideas into tangible assets:
                                </p>
                                <p>
                                    <ul style={listStyles} class="sec-clr f-16">
                                        <li>Generate licensing revenue from your patents</li>
                                        <li>Use patents as collateral for financing</li>
                                        <li>Improve your negotiating position in partnerships and M&A deals</li>
                                    </ul>
                                </p>
                            </div>
                            <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                                <img src="/assets/img/strategy/strategy-3.jpeg" style={{ width: '100%'}} />
                            </div>
                        </div>
                    </div>
                </section>

                <section class="provisionalBuilder-sec-4 mt-3 mb-3 pt-3 pb-3 bg-light-blue">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-center col-md-12 wow fadeInLeft delay-04s">
                                <h1 class="sec-clr bold">
                                    Why Choose Us?
                                </h1>
                                <p class="sec-clr f-16">
                                        20+ years of experience in patent law and strategy.<br/>
                                        Proven track record of increasing client valuations. 
                                        Deep expertise in technology and innovation
                                </p>
                                <p class="sec-clr f-16">
                                    Don't leave your company's future to chance. Let us help you craft a patent strategy that drives growth and protects your market position.
                                </p>
                                <button id='registerBtn' type='button' onClick={openPopup} class="btn-default btn">Schedule Your Free Patent Strategy Consultation</button>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
            <PatentStrategyPopup ref={childRef}></PatentStrategyPopup>
        </>
    )
};

export default PatentStrategy;